@font-face {
  font-family: "Bangla";
  src: url("./assets/fonts/Bangla.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  font-family: "Bangla", "Roboto";
}

.sans {
  font-family: "Open Sans", sans-serif !important;
}

.bn {
  font-family: "Bangla", sans-serif !important;
}

@media (max-width: 991px) {
  #logo {
    display: none !important;
  }
}

#nid_front,
#nid_back {
  width: 325px;
  height: 205.5px;
}
