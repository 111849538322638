@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply max-w-screen-xl mx-auto px-4 lg:px-6;
  }

  .base_input_wrapper {
    @apply col-span-12 xl:col-span-6 flex flex-col gap-y-1;
  }
  .base_input_wrapper.full {
    @apply lg:col-span-12;
  }
  .base_input_wrapper > label {
    @apply flex items-center justify-start gap-x-1;
  }
  .base_input {
    @apply w-full h-10 outline-none ring-2 ring-gray-300 focus:ring-purple-600 p-2 rounded-sm bg-white;
  }

  .blood_group_btns {
    @apply flex flex-wrap gap-2 justify-end;
  }
  .blood_group_btns > button {
    @apply px-2 py-1 rounded focus:ring bg-purple-600 text-white text-xs;
  }
}
